import mf from "../../../assets/mf.png"
import paperless from "../../../assets/paperless.png"
import bsh from "../../../assets/bsh.png"

  const OurMotivesCard = [
    {
     image:mf,
     text:"Explore 3500+ mutual funds available"
    },
    {
     image:paperless,
     text:"Easy, paperless investing"
    },
    {
     image:bsh,
     text:"Get Buy-Sell-Hold mutual fund recommendations"
    }
]

export default OurMotivesCard;
