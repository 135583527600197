import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedNav } from '../../redux/actions/headerAction';
import { Navbar, Button } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import LOGO from '../../assets/elevo/ELEVO.png';
import { getFreshChatStatus } from '../../api/freshchat';

const Header = () => {
  const { activeLink } = useSelector((state) => state.headerReducer);
  const [blogUrl,setBlogUrl] = useState('')
  const [aboutUrl,setAboutUrl] = useState('')
  console.log(activeLink)
  const dispatch = useDispatch();
  const updateActiveLink = (link) => {
    dispatch(updateSelectedNav(link));
  };
  useEffect(() => {
    getFreshChatStatus().then((res) => {
      setBlogUrl(res?.data?.blog_url)
      setAboutUrl(res?.data?.about_us_url)
    })
  }, [])

   return (
      <>
        <Navbar collapseOnSelect expand="lg"  variant="light" className="sticky-top navbar-light bg-white mt-2 mb-4 mx-4">
            <Link className="navbar-brand" to="/">
              <img src={LOGO} className="logo" alt="Tarrakki Towards Prosperity" />
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" /> 
            <Navbar.Collapse className="font-weight-medium"> 
             <ul className="navbar-nav ml-auto mt-2">
                <li className={`nav-item mr-4 ${activeLink === 'homepage' && 'active'}`} onClick={() => updateActiveLink('homepage')}>
                  <Link className="nav-link" smooth to='/'>
                    Home
                  </Link>
                </li>
                { aboutUrl && (
                  <li className={`nav-item mr-4 ${activeLink === 'aboutus' && 'active'}`} onClick={() => updateActiveLink('aboutus')}>
                    <a href={aboutUrl} target="_blank" rel="noreferrer" className="nav-link">
                      About Us
                    </a>
                  </li>
                )}
                { blogUrl && (
                  <li className={`nav-item mr-4 ${activeLink === 'blog' && 'active'}`} onClick={() => updateActiveLink('blog')}>
                    <a href={blogUrl} target="_blank" rel="noreferrer" className='nav-link'>Blog</a>
                  </li>
                )}
                <li className={`nav-item  ${activeLink === 'login' && 'active'}`} onClick={() => updateActiveLink('login')}>
                  <a href={process.env.REACT_APP_AUTH_URL}>
                    <Button variant="outline-primary"  className=".btn-outline-primary">Login</Button>
                  </a>
                </li>
             </ul>
            </Navbar.Collapse>
        </Navbar>
      </>
  );
};
Header.propTypes = {
  history: PropTypes.object,
};
export default withRouter(Header);