import React from "react"

const Privacypolicy = () => {
    return <div className="wrapper inner-page container" id="privacy">
    <section className=" termofservice privacy">
      <h3 className="privacy-header">Privacy Policy</h3>
      <ul>
        <li>
          <span>1.</span>
            This Privacy Policy Statement (“ <b>Policy</b> ”) outlines Plutonomic Savtech Private Limited’s (“ <b>Company</b>
          ”, “ <b>we</b> ” or
          “ <b>us </b> ”) practice in relation to the storage, use, processing, and disclosure of personal data that
          you have
          chosen to share with us when you access our website <a href="https://www.elevo.money"className="email">https://www.elevo.money</a>
        </li>
        <li>
          <span>2.</span>
          We are committed to protecting your personal data and respecting your privacy.
        </li>
        <li>
          <span>3.</span>
          Please read the following terms of the Policy carefully to understand our practices regarding your personal
          data and how we will treat it. This policy sets out the basis on which any personal data we collect from you,
          or that you provide to us, will be processed by us.
        </li>
        <li>
          <span>4.</span>
          Capitalised words in the Policy shall have the same meaning ascribed to them in the Terms and Conditions
          (“ <b>Terms</b> ”), available at <a href="https://www.elevo.money/tos/"className="email">https://www.elevo.money/tos/</a>. Please read this Policy in consonance with the Terms.

        </li>
        <li>
          <span>5.</span>
          By using the Platform, you consent to the collection, storage, use, and disclosure of your personal data, in
          accordance with, and are agreeing to be bound by this Policy. We will not collect any information from you,
          except where it is knowingly and explicitly provided by you.
        </li>
        <li>
          <span>6.</span>
          This Policy together with our Terms applies to your use of:
          <ul>
            <li>
              <span>a.</span>
              The Platform, once you have downloaded or streamed a copy of the same onto your mobile telephone or
              handheld device or computer (“ <b>Device</b> ”).
            </li>
            <li>
              <span>b.</span>
              Any of the services accessible through the Platform (“ <b>Services</b> ”) that are available on the
              Platform.
            </li>
          </ul>
        </li>
        <li>
          <span>7.</span>
          This Platform is not intended for children and we do not knowingly collect data relating to children.
        </li>
        <li>
          <span>8.</span>
          We will handle personal data appropriately, in line with the circumstances. If any intended use of personal
          data goes beyond the purposes envisioned during collection, we will notify you of such new purpose(s) to use
          your personal data.
        </li>
        <li>
          <span>9.</span>
          This policy is provided in a layered format, so you can click through to the specific areas set out below.
          <ul className="dot">
            <li>
              The data we collect about you
            </li>
            <li>
              How is your personal data collected
            </li>
            <li>
              Authenticity of personal data
            </li>
            <li>
              How we use your personal data and for what purposes
            </li>
            <li>
              Transfer of personal data
            </li>
            <li>
              Third party links and user experience improvement services 
            </li>
            <li>
              Cookies
            </li>
            <li>
              Data security
            </li>
            <li>
              Data retention
            </li>
            <li>
              Business transitions
            </li>
            <li>
              Change in privacy policy
            </li>
            <li>
              Grievance Officer
            </li>

          </ul>
          <div className="link">
            Alternatively, you can download a pdf version of the Policy <a target="_blank" href="#" rel="noreferrer">here:</a>.
          </div>
        </li>

      </ul>
      <h3><span>10.</span>
        THE DATA WE COLLECT ABOUT YOU  </h3>
      <ul>
        <li>
          <span>10.1.</span>
          We may collect, use, store and transfer different kinds of personal data about you, including but not
          limited to:
          <ul>
            <li>
              <span>a.</span>
              Identity data
            </li>
            <li>
              <span>b.</span>
              Contact data
            </li>
            <li>
              <span>c.</span>
              Financial data
            </li>
            <li>
              <span>d.</span>
              Transaction data
            </li>
            <li>
              <span>e.</span>
              Device data
            </li>
            <li>
              <span>f.</span>
              Content data
            </li>
            <li>
              <span>g.</span>
              Profile data
            </li>
            <li>
              <span>h.</span>
              Usage data
            </li>
            <li>
              <span>i.</span>
              Marketing and communications data
            </li>
          </ul>
        </li>
        <li>
          <span>10.2.</span>
          We also collect, use and share aggregated data such as statistical or demographic data for any purpose.
          Aggregated data could be derived from your personal data but is not considered personal data in law as this
          data will not directly or indirectly reveal your identity. For example, we may aggregate your usage data to
          calculate the percentage of users accessing a specific Platform feature. However, if we combine or connect
          aggregated data with your personal data so that it can directly or indirectly identify you, we treat the
          combined data as personal data which will be used in accordance with this Policy.
        </li>
        <li>
          <span>10.3.</span>
          We do not collect any special categories of personal data about you (this includes details about your race or
          ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union
          membership, information about your health, and genetic and biometric data). Nor do we collect any information
          about criminal convictions and offences.
        </li>
        <li>
          <span>10.4.</span>
          You are entitled to receive a copy of the personal information you have provided to us, if you would like a
          copy of the same, please reach out to us as provided in Clause 21.1.
        </li>
      </ul>
      <h3><span>11.</span>
        HOW IS YOUR PERSONAL DATA COLLECTED?  
      </h3>
      <ul>
        <li>
          <span>11.1.</span>
          We will collect and process the following data about you:
          <ul>
            <li>
              <span>a.</span>
              <u>Information you give us</u> - This is the information (including identity, contact, financial, and
              marketing and communications data) you consent to giving us about you by filling in forms on the
              Platform, or by corresponding with us (for example, by email or chat). It includes information you
              provide when you register to use the Platform, download or register or subscribe to any of our Services,
              search for a Service, make an in-Platform purchase, share data via Platform’s social media functions,
              enter a competition, promotion or survey, or other activities commonly carried out in connection with the
              Platform and when you report a problem with the Platform and our Services. If you contact us, we will
              keep a record of the information shared during the correspondence.
            </li>
            <li>
              <span>b.</span>
              Information we collect about you and your device - Each time you visit our Platform or use one of our
              Services, we will automatically collect personal data including Device, content and usage data. We
              collect this data using cookies and other similar technologies.
            </li>
            <li>
              <span>c.</span>
              Information we receive from other sources including third parties and publicly available sources - We
              will receive personal data about you from various third parties and public sources as set out below:
              <ul>
                <li>
                  <span>i. </span>
                  Analytics providers such as Google and Facebook pixel;
                </li>
                <li>
                  <span>ii.</span>
                  Advertising networks;
                </li>
                <li>
                  <span>iii. </span>
                  Search information providers;
                </li>
                <li>
                  <span> iv.</span>
                  Contact, financial and transaction data from providers of technical, payment and delivery
                  services;
                </li>
                <li>
                  <span> v.</span>
                  Identity and contact data from data brokers or aggregators; and
                </li>
                <li>
                  <span>vi. </span>
                  Identity and contact data from publicly available sources.
                </li>
              </ul>
            </li>

          </ul>
        </li>
      </ul>
      <h3><span>12.</span>
        AUTHENTICITY OF INFORMATION
      </h3>
      <ul>
        <li>
          <span>12.1.</span>
          We have taken all reasonable steps to ensure that the information on the Platform is authentic.
        </li>
        <li>
          <span>12.2.</span>
          You agree that the information and personal data you provide us with are true, correct, and accurate. We
          shall not be liable for any incorrect or false information or personal data that you might provide.
        </li>
        <li>
          <span>12.3.</span>
          You may review the information and personal data that you have provided during your use of the Platform and
          choose to correct or modify the same. You may do so by writing to us or our Grievance Officer at the address
          provided in Section 21 of this Policy.
        </li>
      </ul>
      <h3>
        <span>13.</span>
        HOW WE USE YOUR PERSONAL DATA AND FOR WHAT PURPOSES
      </h3>
      <ul>
        <li>
          <span>13.1.</span>
          You understand that when you consent to providing us with your personal data, you also consent to us sharing
          the same with third parties.
        </li>
        <li>
          <span>13.2.</span>
          You are aware that by using and/or creating an account with the Platform, you authorise us, our associate
          partners and affiliates to contact you via email, phone, or otherwise. This is to ensure that you are aware
          of all the features of the Platform.
        </li>
        <li>
          <span>13.3.</span>
          You authorise us to send you electronic alerts for details pertaining to registration, other service
          requirements, and advertising.
        </li>
        <li>
          <span>13.4.</span>
          Further, you agree to receive promotional and other emails and other forms of communication from us. Through
          such communication you will receive information about the latest developments on the Platform. You may
          unsubscribe from our mailing list at any time, via the unsubscribe/opt out option we offer.
        </li>
        <li>
          <span>13.5.</span>
          You are aware that any and all information pertaining to you, whether or not you directly provide it to us
          (via the Platform or otherwise), including but not limited to personal correspondence such as emails or
          letters, instructions from you etc., may be collected, compiled and shared by us in order to render Services
          to you and you expressly consent to the same.
        </li>
        <li>
          <span>13.6.</span>
          In general, we will not disclose personal data except in accordance with the following purpose/activity:
          <ul>
            <li>
              <span>a.</span>
              To install the Platform and register you as a user;

            </li>
            <li>
              <span>b.</span>
              To process in-Platform purchases and deliver Services including managing payments;

            </li>
            <li>
              <span>c.</span>
              To manage our relationship with you including notifying you of changes to the Platform or any
              Services;
            </li>
            <li>
              <span>d.</span>

              To administer and protect our business and this Platform including troubleshooting, data analysis and
              system testing;

            </li>
            <li>
              <span>e.</span>
              To deliver content and advertisements to you;

            </li>
            <li>
              <span>f.</span>
              To make recommendations to you about goods or services which may interest you;

            </li>
            <li>
              <span>g.</span>
              To measure and analyse the effectiveness of the advertising we serve you;

            </li>
            <li>
              <span>h.</span>
              To monitor trends so we can improve the Platform;

            </li>

            <li>
              <span>i.</span>
              To perform a contract, we are about to enter or have entered with you;

            </li>
            <li>
              <span>j.</span>
              To enforce our Terms;

            </li>
            <li>
              <span>k.</span>
              Where it is necessary for our legitimate interests (or those of a third party) and your interests and
              fundamental rights do not override those interests;

            </li>
            <li>
              <span>l.</span>
              To comply with a legal or regulatory obligation.
            </li>
          </ul>
        </li>
      </ul>
      <h3><span>14.</span>
        TRANSFER OF PERSONAL DATA 
      </h3>
      <ul>
        <li>
          <span>14.1.</span>
          As a part of your use of the Platform, the information and personal data you provide to us may be transferred
          to and stored at countries other than India. This may happen if any of our servers are from time to time
          located in a country other than India, or one of our service providers is located in a country other than
          India. We may also share information with entities of countries other than India. These countries shall be
          subject to data laws of their respective countries.

        </li>
        <li>
          <span>14.2.</span>
          If you use the Platform while you are outside India, your information may be transferred to a country other
          than India in order to provide you with the Platform.
        </li>
        <li>
          <span>14.3.</span>
          By submitting your information and personal data to us, you agree to the transfer, storage, and/or processing of such information and personal data outside India in the manner described above.
        </li>
      </ul>
      <h3><span>15.</span>
        THIRD PARTY LINKS AND USER EXPERIENCE IMPROVEMENT SERVICES</h3>
      <ul>
        <li>
          <span>15.1.</span>
          Our Platform may, from time to time, contain links to and from the websites of our partner networks,
          advertisers and affiliates (“ <b>Third Party Websites</b> ”). Please note that the Third Party Websites and
          any
          services from them, that may be accessible through our Platform have their own privacy policies. We do not
          accept any responsibility or liability for the policies or for any personal data that may be collected
          through the Third Party Websites or their services. Please check their policies before you submit any
          personal data to such websites or use their services.
        </li>
        <li>
          <span>15.2.</span>
          We use third-party user experience improvement services (including but not limited to those provided by
          Google Inc. and/or its affiliates) and applications to better understand your behavior on the Platform.
        </li>
        <li>
          <span>15.3.</span>
          The information collected includes (but is not limited to):
          <ul>
            <li>
              <span>a.</span>
              Age;
            </li>
            <li>
              <span>b.</span>
              Gender;
            </li>
            <li>
              <span>c.</span>
              Preferences; and
            </li>
            <li>
              <span>d.</span>
              Interests.
            </li>
          </ul>

        </li>
        <li>
          <span>15.4.</span>
          Your relationship with these third parties and their services and tools is independent of your relationship
          with us. These third parties may allow you to permit/restrict the information that is collected. It may be in
          your interest to individually restrict or enable such data collections.
        </li>
        <li>
          <span>15.5.</span>
          The place of processing information depends on each third-party service provider and you may wish to check
          the privacy policy of each of the service providers to identify the data shared and its purpose. You will be
          subject to a third party’s privacy policy if you opt in to receive communications from third parties. We will
          not be responsible for the privacy standards and practices of third parties.

        </li>
      </ul>

      <h3><span>16.</span>
        COOKIES  </h3>
      <ul>
        <li>
          <span>16.1.</span>
          We use cookies and/or other tracking technologies to distinguish you from other users of the Platform, the
          distribution platform and to remember your preferences. This helps us to provide you with a good experience
          when you use or browse our Platform and also allows us to improve the Platform.
        </li>
        <li>
          <span>16.2.</span>
          We collect data by way of ‘cookies’. Cookies are small data files which are sent to your browser from the
          Platform and are stored on your computer or device (hard drive). The cookies shall not provide access to data
          in your computer or device such as email addresses or any other data that can be traced to you personally.
          The data collected by way of cookies will allow us to administer the Platform and provide you with a tailored
          and user-friendly service. The cookies shall enable you to access certain features or Services of the
          Platform. Most web browsers and devices can be set to notify when you receive a cookie or prevent cookies
          from being sent. If you do prevent cookies from being sent, it may limit the functionality that we can
          provide when you visit the Platform.
        </li>
        <li>
          <span>16.3.</span>
          Additionally, you may encounter cookies or other similar devices on certain pages of the Platform that are
          placed by third parties. We do not control the use of cookies by third parties. If you send us personal
          correspondence, such as emails or letters, or if other users or third parties send us correspondence about
          your activities or postings on the Platform, we may collect such information into a file specific to you.
        </li>
      </ul>

      <h3><span>17.</span>
        DATA SECURITY  
      </h3>
      <ul>
        <li>
          <span>17.1.</span>
          We implement certain security measures including encryption, firewalls, and socket layer technology to
          protect your personal information from unauthorised access and such security measures are in compliance with
          the security practices and procedures as prescribed under the Information Technology Act, 2000 and the
          applicable rules (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information
          Rules, 2011). However, you agree and acknowledge that the above-mentioned measures do not guarantee absolute
          protection to the personal information and by accessing the Platform, you agree to assume all risks
          associated with disclosure of personal information arising due to breach of firewalls and secure server
          software.
        </li>
        <li>
          <span>17.2.</span>
          Where we have given you (or where you have chosen) a password that enables you to access certain parts of our
          Platform, you are responsible for keeping this password confidential. We ask you not to share the password
          with anyone.
        </li>
        <li>
          <span>17.3.</span>
          We will comply with the requirements under the Information Technology Act, 2000 and the rules made thereunder
          in the event of a data or security risk
        </li>
      </ul>
      <h3><span>18.</span> DATA RETENTION  </h3>
      <ul>
        <li>
          <span>18.1.</span>
          You are aware that your personal data will continue to be stored and retained by us for a reasonable period
          after termination of your account on the Platform, and that any and all content or information uploaded by
          you may be retained and/or displayed on the Platform indefinitely.

        </li>
      </ul>
      <h3><span>19.</span>
        BUSINESS TRANSITIONS
      </h3>
      <ul>
        <li>
          <span>19.1.</span>
          You are aware that in the event we go through a business transition, such as a merger, acquisition by another
          organisation, or sale of all or a portion of our assets, your personal data might be among the assets
          transferred.
        </li>
      </ul>
      <h3><span>20.</span>
        CHANGE IN PRIVACY POLICY
      </h3>
      <ul>
        <li>
          <span>20.1.</span>
          <u>Changes to the privacy policy and your duty to inform us of changes:</u>
          <ul>
            <li>
              <span>a.</span>
              We keep our Policy under regular review and may amend this Policy from time to time, at our sole
              discretion.
            </li>
            <li>
              <span>b.</span>
              This version was last updated on 23<sup>rd</sup> December 2020. It may change and if it does, these changes will be posted
              on this page and, where appropriate, notified to you by email. The new Policy may be displayed on-screen
              and you may be required to read and accept the changes to continue your use of the Platform and the
              Services.
            </li>
          </ul>
        </li>
      </ul>
      <h3><span>21.</span>
        GRIEVANCE OFFICER
      </h3>
      <ul>
        <li>
          <span>21.1.</span>
          You may contact us at <a href="mailto:support@elevo.money"className="email">support@elevo.money</a> with any enquiry relating to this Policy or an enquiry relating
          to your personal information (review, update, deletion). You can also do so by writing to our Grievance
          Officer, established in accordance with the Information Technology Act, 2000 and rules made thereunder, at
          the address provided below:
          <div className="link">
              Name : Saumya Shah 
              <br />
              Address: 801 Sapphire Complex, Opposite Union Bank of India Near Cargo Motors, C.G Road, Ahmedabad-380006
              <br />
              Email : <a href="mailto:support@elevo.money">support@elevo.money</a>
          </div>
        </li>
      </ul>
    </section>
  </div>
}

export default Privacypolicy 