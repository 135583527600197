import axios from 'axios';
import Toast from '../toast/Toast';

const getLoader = () => {
  
  const loaderHtml = '<div id="loader"><div class="loader"><div></div><div></div><div></div><div></div>';
  const temp = document.createElement('div');
  temp.innerHTML = loaderHtml;
  return temp.firstChild;
};
const removeLoader = () => {
  const selector = document.getElementById('loader');
  if (selector) {
    selector.remove();
  }
};

const fetchClient = (version) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_TARRAKKI_LEGACY_API_URL + version,
    headers: { 'api-key': atob(process.env.REACT_APP_API_KEY) },
  });

  // Add a request interceptor
  instance.interceptors.request.use((config) => {
    document.body.appendChild(getLoader());
     return config;
  });

  // Add a response interceptor
  instance.interceptors.response.use(
    (res) => {
      removeLoader();
      if (res && res.data && res.data.status) {
        if (res.data.status.message && res.data.status.code === 0) {
          Toast.error(res.data.status.message);
        }
      }
      return res;
    },
    (error) => {
      removeLoader();
      console.log(error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('userData');
        window.location.href = '/home';
      } else if (error.response && error.response.status) {
        Toast.error(error.response.statusText);
      } else if (error.message) {
        Toast.error(error.message);
      }
      return Promise.reject(error);
    }
  );
  return instance;
};
export default fetchClient('v6');
