import React, { useState } from 'react'
import { Card, CardGroup, CardImg, Container, Row, Col } from 'react-bootstrap'
import TaxStatusCards from "./TaxStatusCards"
import { BsArrowRight } from "react-icons/bs"

const TaxStatus = () => {
    const [cardData,setCardData] = useState(TaxStatusCards[0])
  return (
    <>
     <section className="tax-status">
        <h2 className="header-title">What is your tax status?</h2>
        <p className="ourmotives-paragraph">Start your investment journey to manage your business treasury more efficiently</p>
        <Container className="taxstatus-container">
          <Row>
            <Col>
            <CardGroup className="taxstatus-card" >
               {TaxStatusCards.map((card)=>{
                  return <>
                         <Card  id="taxstatus-card" onClick={() =>  setCardData(card)}>
                         <CardImg variant="top" src={card.icon} id="taxstatus-img"></CardImg>
                         <Card.Text>{card.text}</Card.Text>
                         </Card>
                        </>
                         })}
            </CardGroup>
            </Col>
            <Col id="taxstatus-col">
              <CardGroup>
                  <Card id="taxstatus-card">
                      <CardImg src={cardData.icon} id="taxstatus-img"></CardImg>
                      <Card.Title>{cardData.title}</Card.Title>
                      <Card.Subtitle id="taxstatus-subtitle">{cardData.subTitle}</Card.Subtitle>
                      <Card.Text>{cardData.header}</Card.Text>
                      <ul >
                          {cardData.description.map((value) => {return <><li>{value}</li></>})}
                      </ul>
                      <Card.Link href={process.env.REACT_APP_AUTH_URL}>Invest now   <BsArrowRight size={28}/></Card.Link>
                  </Card>
              </CardGroup>
            </Col>
            </Row>
        </Container> 
        </section>
        </>
    )
}
export default TaxStatus;
