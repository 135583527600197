import React from 'react'
import { Card, CardGroup, CardImg } from 'react-bootstrap'
import OurMotivesCard from "./OurMotivesCards"

const OurMotives = () => {
    return (
        <>
        <section className="core-values" id="Whyelevo">
            <h2 className="header-title">Why Elevo?</h2>
            <p className="ourmotives-paragraph">Elevo is designed for non-individual investors, with features such as</p>
            <CardGroup  id="card-group">
                 {OurMotivesCard.map(card=>{
                     return <>
                             <Card id="ourmotives-card">
                             <CardImg variant="top" src={card.image} id="ourmotives-img"></CardImg>
                             <Card.Text>{card.text}</Card.Text>
                             </Card>
                            </>
                     })}
            </CardGroup>
        </section>
        </>
    )
}
export default OurMotives;
