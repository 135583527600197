import React, { useEffect,  useState } from "react";
import { getReview } from "../../../api/Review";
import { decryptData } from '../../../utility/DecryptHelper';
import  Carousel  from "react-multi-carousel";
import { Card, CardGroup } from 'react-bootstrap'
import "react-multi-carousel/lib/styles.css";

const UsersReviews = () => {
    const [review, setReview] = useState([]);
    useEffect(()=>{
       getReview().then((res) => {
        if (res && res.data) {
          const reviewData = decryptData(res.data.data);
          if(reviewData){
            setReview(reviewData.data)
          }
       }})
     },[])

     const responsive =  {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          paritialVisibilityGutter: 60,
          slidesToSlide: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          paritialVisibilityGutter: 50,
          slidesToSlide: 1 
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          paritialVisibilityGutter: 30,
          slidesToSlide: 1 
        }
      };
     return (
         <>
          <section className="review-status mr-4 ml-4 ">
           <h2 className="header-title">What our clients say</h2>
           <Carousel
             autoPlay
             paritialVisibilityGutter
             swipeable={false}
             draggable={true}
             showDots={true}
             responsive={responsive}
             ssr={true} 
             infinite={true}
             autoPlaySpeed={2000}
             keyBoardControl={true}
             customTransition="all .5"
             transitionDuration={500}
             removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
             dotListClass="custom-dot-list-style"
             itemClass="carousel-item-padding-40-px"
           >
          {review && 
            review.map((user)=>{
            return <>
                <div id="" key={user.id} className="colored-box review-card-container">
                <CardGroup>
                <Card id="review-card" className="justify-content-start">
                <Card.Img variant="top" src={user.image} className='mobile-review-img'/>
                <Card.Title className="mb-0 font-weight-normal">{user.name}</Card.Title>
                <Card.Subtitle className="mb-4 opacity-75">{user.designation}</Card.Subtitle>
                <Card.Text className="opacity-75">{user.review}</Card.Text>
                </Card>
                </CardGroup>
                </div>
              </>
            }
           )
          }
         </Carousel>
         </section>
         </>
     )
}

export default UsersReviews