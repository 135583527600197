import React from 'react'
import { Card, CardGroup, CardImg } from 'react-bootstrap'
import  OurValuesCards  from './OurValuesCards'

const OurValues = () => {
    return (
        <section className="our-values container" >
         <h2 className="header-title values-header">Our Core Values</h2>
            <CardGroup className="ourcore-values">
               {OurValuesCards.map(card=>{
                  return <>
                   <Card id="ourvalues-card">
                     <CardImg variant="top" src={card.image} id="ourvalues-img">
                     </CardImg>
                     <Card.Title>{card.title}</Card.Title>
                     <Card.Text className="card-text">{card.text}<span dangerouslySetInnerHTML={{__html:card["link"]}}></span></Card.Text>
                   </Card>
               </>
               })}
            </CardGroup>
        </section>
    )
}

export default OurValues;
