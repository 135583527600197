import llp from "../../../assets/llp.png";
import partnership from "../../../assets/partnership.png";
import individuals from "../../../assets/individuals.png";
import huf from "../../../assets/huf.png";
import limited from "../../../assets/limited.png";
import minor from "../../../assets/minor.png";
import nrinronre from "../../../assets/nri-nro-nre.png";
import privatelimited from "../../../assets/privatelimited.png";
import proprietorship from "../../../assets/proprietorship.png";
import trust from "../../../assets/trust.png";

let TaxStatusCards = [
  {
    id: 1,
    text: "Limited Liability Partnership(LLP)",
    name:"LLP",
    icon: llp,
    title: "Are you a Limited Liability Partnership (LLP) and looking to invest as an entity ?",
    subTitle: "Start your investment journey today with Elevo",
    header: "Documents required for investing:",
    description: [
      "PAN card copy of LLP",
      "Board Resolution",
      "PAN card and address proof of all the partners"
    ]
  },
  {
    id: 2,
    text: "Partnership",
    name: "Partnership",
    icon: partnership,
    title: "Are you running a partnership firm and considering to invest as a non individual?",
    subTitle: "Invest with Elevo today",
    header: "Documents required for investing:",
    description: [
      "PAN card copy of partnership firm",
      "Partnership deed",
      "PAN card and address proof of all the partners"
    ]
  },
  {
    id: 3,
    text: "Proprietorship",
    name: "Proprietorship",
    icon: proprietorship,
    title: "Do you have a proprietary firm and considering to invest as a non individual? ",
    subTitle: "Invest with Elevo today",
    header: "Documents required for investing:",
    description: ["PAN card"]
  },
  {
    id: 4,
    text: "Private Limited",
    name: "PrivateLimited",
    icon: privatelimited,
    title: "Do you own a private limited company and planning to invest?",
    subTitle: "Start investing today with Elevo",
    header: "Documents required for investing:",
    description: [
      "PAN card",
      "Board resolution",
      "Authorized signatory list",
      "PAN card and address proof of all the directors"
    ]
  },
  {
    id: 5,
    text: "Limited",
    name: "Limited",
    icon: limited,
    title: "Do you own a limited firm and considering to invest as a non-individual? ",
    subTitle: "Start your investment journey with a Elevo today ",
    header: "Documents required for investing:",
    description: [
      "PAN card",
      "Board resolution",
      "Authorized signatory list",
      "PAN card and address proof of all the directors"
    ]
  },
  {
    id: 6,
    text: "Hindu Undivided Family(HUF)",
    name: "HUF",
    icon: huf,
    title: "Are you a Karta of an HUF and planning to start your investment journey?",
    subTitle: "Elevo is here for that",
    header: "Essential documents for starting to invest on Elevo: ",
    description: [
      "HUF PAN copy",
      "PAN card and address proof of all HUF members"
    ]
  },
  {
    id: 7,
    text: "NRI/NRO/NRE",
    name: "Nri/Nre/Nro",
    icon: nrinronre,
    title: "Living Abroad? And want to invest in India?",
    subTitle: "Elevo is here for you",
    header: "Documents required to start investing: ",
    description: ["PAN card", "NRE/NRO account", "Bank account details"]
  },
  {
    id: 8,
    text: "Minor",
    name: "Minor",
    icon: minor,
    title: "Are you a parent and want to invest on behalf of your child who is below 18?",
    subTitle: "Are you a parent and want to invest on behalf of your child who is below 18?",
    header: "Start investing with Elevo",
    description: [
      "Birth certificate of the minor, school living certificate or any other proof or any other proof showing date of birth of the minor",
      "Bank details"
    ]
  },
  {
    id: 9,
    text: "Trust",
    name: "Trust",
    icon: trust,
    title: "Have created a trust, and want to invest the assets?",
    subTitle: "Start your investment journey with a Elevo today",
    header: "Documents required for investing:",
    description: [
      "PAN card",
      "Board resolution",
      "Authorized signatory list",
      "PAN card and address proof of the all the trustees",
      "Bank details"
    ]
  },
  {
    id: 10,
    text: "Individuals",
    name: "individuals",
    icon: individuals,
    title: "Are you an individual and looking to invest?",
    subTitle: "",
    header: "Add your PAN and bank details to start investing.",
    description: []
  }
];
export default TaxStatusCards;
