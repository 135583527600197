import React from 'react'
import { Button } from 'react-bootstrap'
import HomePageImage from "../../../assets/group3516.png"

const Introduction = () => {
   return (
        <>
           <section className="intro-section" >
             <div>
             <h1 className="intro-heading ">Investing for everyone</h1>
             <p className="intro-paragraph">
               ELEVO is a unique digital investment platform that not only caters to individuals but HUFs, Minors, Companies such as Proprietorship firms, Partnerships, Private and Limited companies as well.
             </p>
               <a href={`${process.env.REACT_APP_AUTH_URL}/signup`}>
               <Button variant="contained"  id="intro-btn" className="mt-2 mb-4 btn-primary">
                 Join now
               </Button>
               </a>
             </div>
             <div>
               <img src={HomePageImage} alt="types of investors" className="intro-image"/>
             </div>
           </section>
        </>
    )
}
export default Introduction;

