import React from 'react';
import bse_icon from '../../assets/bse.png';
import amfi_icon from '../../assets/amfi.png';
import sebi_icon from '../../assets/sebi.png';
import { HashLink as Link } from "react-router-hash-link"
import tarrakkiLogo from '../../assets//elevo/ELEVO.png';
import { useLocation } from "react-router-dom";
import { GoPrimitiveDot} from "react-icons/go"
import {
    FaFacebookF,
    FaInstagram,
    FaTwitter,
    FaLinkedinIn,
    FaYoutube
  } from "react-icons/fa";

const Footer = () => {
  const location = useLocation();
  const pathName = location.pathname === "/homepage";
   return (
    <section className="text-black mobile-footer pt-4 "> 
        <img src={tarrakkiLogo} alt="Tarrakki Towards Prosperity" className="w-100px mobilefooter-logo mt-4 mb-4"/>
         <div className='legal-logos mt-2 mb-2'>
         <img src={bse_icon} alt="icon-1"  className="w-50" id="img-fluid"/>
         <img src={amfi_icon} alt="icon-2" className="w-50"  id="img-fluid"/>
         <img src={sebi_icon} alt="icon-3" className="w-50" id="img-fluid"/> 
         </div>
         <div className='about-links mt-4 mb-2'>
          <p className="footerpara mt-2 mr-2"><Link to='/AboutUs' smooth className="hyperlink">About Us</Link></p><GoPrimitiveDot />
          <p className="footerpara mt-2 mr-2"><Link to='/whyelevo' smooth className="hyperlink">Why Elevo</Link></p><GoPrimitiveDot />
          <p className="footerpara mt-2"><a href="https://www.tarrakki.com/news" smooth className="hyperlink">Press</a></p>
          </div>
          <div id='mobile-address' >
             <p className="footerpara">801 Sapphire Complex,</p>
              <p className="footerpara">Opposite Union Bank of India,</p>
              <p className="footerpara">Chimanlal Girdharlal Rd,</p>
              <p className="footerpara">Near Cargo Motors,</p>
              <p className="footerpara">Ahmedabad, Gujarat 380006</p>
              </div>
              <span className="icons">
              <a href="https://www.facebook.com/elevo.money/" target="_blank" rel="noreferrer"> <FaFacebookF size={20} color="black"/></a>
              </span>
              <span className="icons">
              <a href="https://instagram.com/elevo.money?utm_medium=copy_link" target="_blank" rel="noreferrer"><FaInstagram size={20} color="black"/></a>
              </span>
              <span className="icons">
              <a href="https://twitter.com/elevo_?s=21" target="_blank" rel="noreferrer"><FaTwitter size={20} color="black"/></a>
              </span>
              <span className="icons">
              <a href="https://www.linkedin.com/company/tarrakki/" target="_blank" rel="noreferrer"><FaLinkedinIn size={20} color="black"/></a>
              </span>
              <span className="icons">
              <a href="https://m.youtube.com/channel/UC4vQy-jvqZvrSgngpoFG-_g" target="_blank" rel="noreferrer"><FaYoutube size={20} color="black"/></a>
              </span>
              
      <hr />
     <div className='copyright container mt-4 '>
          <span className='mb-4 termsofservice'>
          <Link smooth to="/tos#tos" className="hyperlink">Terms Of Service</Link> &
          <Link smooth to="/privacy#privacy" className="hyperlink"> Privacy Policy</Link>
          </span>
        <p className='mt-2'>   &copy; 
        Plutonomic Savtech Pvt Ltd. [CIN:U72900GJ2018PTC101542] All Rights
          Reserved</p>
     </div>
    </section>
  );
};

export default Footer;
