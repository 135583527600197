import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.scss';
import Routers from './routers/Routers';
import  Header from './components/header/Header';
import  HeaderMobile from './components/header/HeaderMobile';
import { BrowserView, MobileView } from 'react-device-detect';
import { getFreshChatStatus } from './api/freshchat';

const App = () => {
  
  function initFreshChat() {
    window.fcWidget.init({
      token: process.env.REACT_APP_FRESH_CHAT_TOKEN,
      host: process.env.REACT_APP_FRESH_CHAT_URL,
     });
  }
  useEffect(() => {
    getFreshChatStatus().then((res) => {
      if(res?.data?.chat_support) {
        initFreshChat();
      }
    })
  }, [])
  return (
     <Router>
       <BrowserView>
         <div className="container-fluid">
           <Header />
           <Routers />
         </div>
       </BrowserView>
       <MobileView>
         <>
           <HeaderMobile />
           <Routers />
         </>
       </MobileView>
     </Router>
    );
  }

export default App;
