import React from "react"

const TermsAndConditions = () => {
 return <>
 <div className="wrapper inner-page container" id="tos">
        <section className="termofservice">
      <h3 className="privacy-header">Terms and Conditions</h3>
      <p>The following are the terms of service (“TOS”) of the ‘Elevo’ website and of <a href="https://www.elevo.money">https://www.elevo.money </a>, including its mobile app, collectively referred to as the “Platform”. The Platform is owned and managed by Plutonomic Savtech Private Limited, a private limited company incorporated under the laws of India (the “Company” or “Elevo” or “us”). The Company is registered with Association of Mutual Funds in India (“AMFI”) and has obtained ARN code 147985. The company is also registered with SEBI as an Investment adviser under SEBI (Investment Advisers) regulation 2013 having Reg. No. INA000011079. It is clarified that the company provides distribution services as contemplated under this TOS through its distribution department only.</p>
      <p>These TOS may be updated by us, without notice to you and the TOS, as amended, shall apply to you from time to time. You can always review the most current version of these TOS on this Platform. In addition to these TOS, you are also governed by the privacy policy and any other terms that the Company may notify from time to time (“Additional Terms”). </p>
      <h3><span>1.</span>DEFINITIONS</h3>
      <ul className="tos-links">
        <li><span>1.1 </span>“Account” refers to the account created by a User on the Platform, to view, access and avail the Services provided on the Platform.</li>
        <li><span>1.2 </span>“AMC” refers to an Asset Management Company.</li>
        <li><span>1.3 </span>“Content” refers to any content available on the Platform (or provided by any other means to the User), including without limitation Third Party Content, photographs, drawings, statistics, charts, reports, graphics, technical and non-technical data, questions and answers, testimonials and advice.</li>
        <li><span>1.4 </span>“Cut-Off Time” refers to the cut-off time for the purchase and redemption of all mutual funds (liquid, equity and debt funds) as prescribed by the AMC. Thus, if you redeem or sell the units of a mutual fund before the Cut-Off Time, you would get your redemption amount as per the fund’s NAV of the same day. However, in case you redeem your mutual fund units after Cut-Off Time, the value of your redemption amount will be as per the fund’s NAV of the next business day.</li>
        <li><span>1.5 </span>“NAV” refers to the Net Asset Value of mutual funds.</li>
        <li><span>1.6 </span>“Proprietary Software” refers to the software developed by the Company to undertake risk assessment subject to User’s requirements.</li>
        <li><span>1.7 </span>“Scheme Related Documents” refers to the offer document, scheme information document, statement of additional information, key information memorandum, issued by the AMC that manages the mutual fund.</li>
        <li><span>1.8 </span>“SEBI” refers to the Securities and Exchange Board of India.</li>
        <li><span>1.9 </span>“Services” refers to the services defined in paragraph 2.</li>
        <li><span>1.10 </span>“SIP” refers to Systematic Investment Plans.</li>
        <li><span>1.11 </span>“Third Party Content” refers to the meaning ascribed to it in paragraph 9.1.</li>
        <li><span>1.12 </span>“User” or “you” shall mean any legal person or entity accessing or using the Platform or the Services.</li>
      </ul>
      <h3><span>2.</span>SERVICES</h3>
      <div className="title">The Company provides the following services through its distribution department (“Services”): </div>
      <ul className="tos-links">
        <li><span>2.1 </span>Investment services - The Company shall provide you the facility to invest in various financial products through online/offline mode. The company will provide the said investment facility in regular plans of financial products in the capacity of distributor. </li>
        <li><span>2.2 </span>Investment Reporting – Subject to the investments undertaken via the Platform, the Company shall provide you with reports and data pertaining to the performance of your investments.</li>
        <li><span>2.3 </span>You are authorised to download the reports from the Platform for your reference.</li>
        <li><span>2.4 </span>As per the risk assessment process, the Company will seek details to understand your investment objectives, financial capacity, risk profile and goals (“Parameters”). It must be noted that these Parameters only act as guiding principles in order to render the Services and should not be construed as conclusive. It must be noted that the Company’s risk assessment process is for internal purposes and that it is not customised to cater to specific characteristics of the Users.</li>
      </ul>
      <h3><span>3.</span>ACCOUNT REGISTRATION</h3>
      <ul className="tos-links">
        <li><span>3.1 </span>In order to avail the Services, you will be required to create an Account on the Platform by setting up a username and password which will be linked to your phone number and email address. As per SEBI guidelines (PR No.: 25/2020) - while enabling usage of technology, the intermediary is also required to verify the investor mobile number, e-mail id, and the bank details (through penny drop) to cross verify the information provided by the investor. Hence, Elevo will be verifying your email id, mobile number via OTP, and bank account via penny drop.</li>
        <li><span>3.2 </span>You agree that you shall implement reasonable measures to secure access to:
          <ul className="sub-class">
            <li><span>a.</span>any device associated with the email address or phone number, linked to your Account with the Platform;</li>
            <li><span>b.</span>the username, password and other login or identifying credentials to access the Platform. </li>
          </ul>
        </li>
        <li><span>3.3 </span>You are responsible for maintaining the confidentiality of your username and password, assigned during the Account registration process. You agree to immediately notify the Company of any disclosure or unauthorised use of your Account, or any other breach of security with respect to your Account.</li>
        <li><span>3.4 </span>You must ensure that you log out from your Account at the end of each session to prevent misuse of your Account.</li>
        <li><span>3.5 </span>You expressly agree and consent to be liable and accountable for all activities that take place through your Account. The Company shall in no manner be held liable for any unauthorised investments through your Account due to unauthorised access including but not limited to hacking and security breaches.</li>
      </ul>
      <h3><span>4.</span>PERSONAL INFORMATION</h3>
      <ul className="tos-links">
        <li><span>4.1 </span>In order to access the features of the Platform and use the Services, you may be required to provide certain personal information to the Company. You confirm and verify that the personal information submitted to the Company is accurate and authentic and agree to update the Company if there is any change in the information so submitted.</li>
        <li><span>4.2 </span>All personal information collected by the Company is governed by the Company’s Privacy Policy. PLEASE READ THE PRIVACY POLICY CAREFULLY BEFORE SHARING ANY PERSONAL INFORMATION.</li>
        <li><span>4.3 </span>You hereby authorise the Company to make any inquiries in order to verify your identity and/or protect itself against fraud, and to further take any action that the Company may reasonably deem necessary based on the outcome of such inquiries and reports. You authorise all third parties, to which such inquiries or requests may be directed by the Company, to fully respond to such inquiries or requests of the Company.</li>
      </ul>
      <h3><span>5.</span>ACCOUNT VERIFICATION</h3>
      <ul className="tos-links">
        <li><span>5.1 </span>Your account on the Platform will be activated only upon successful completion of verification process.</li>
        <li><span>5.2 </span>In order for the Company to undertake the verification process, you will be required to share documents for identification purposes. These include, but are not limited to soft copies of:
          <ul className="sub-class">
            <li><span>a.</span>PAN (Permanent Account Number) Card;</li>
            <li><span>b.</span>Passport;</li>
            <li><span>c.</span>Aadhaar Card;</li>
            <li><span>d.</span>Voter ID;</li>
            <li><span>e.</span>Driver’s License;</li>
            <li><span>f.</span>Cancelled Cheque;</li>
            <li><span>g.</span>Address proof;</li>
            <li><span>h.</span>Video of User stating his name, PAN Card number, address proof, time and date;</li>
            <li><span>i.</span>Bank account statement (not older than 3 months from the date of submission of the same).</li>
          </ul>
        <li><span>5.3 </span>The Company may choose to verify your details, based on the information submitted – for setting up an Account. However, if the Company is unable to do so, you will be required to submit additional valid identification documents proving your identity, age and address. </li>
        <li><span>5.4 </span>The Company reserves its right to deactivate the Account if it suspects any discrepancies, fraud, misrepresentation pertaining to the information provided by the User.</li>
        </li>
      </ul>
      <h3><span>6.</span>PROCESSING TRANSACTIONS</h3>
      <ul className="tos-links">
        <li><span>6.1 </span>You understand that the Company partners with third party service providers to process and facilitate the investments.</li>
        <li><span>6.2 </span>Your requests for an investment will be subject to the terms and conditions of such third-party service providers and the AMC.</li>
        <li><span>6.3 </span>The units of the mutual funds shall be allotted, redeemed or switched, as the case may be, at the NAV prevalent on the date of the transaction request by the mutual fund, if the request for purchase, redemption or switch is placed before the Cut-Off Time.</li>
        <li><span>6.4 </span>You understand that any transaction request on a holiday or after the Cut-Off Time will be processed on the next working day and the respective NAV will be applicable as per the Scheme Related Documents.</li>
        <li><span>6.5 </span>The Cut-Off Timings will be prior to the statutory cut-off timing specified under the SEBI Regulations and the Scheme Information Documents for operational convenience.</li>
        <li><span>6.6 </span>You understand that you cannot cancel a transaction after it has been placed on the Platform.</li>
        <li><span>6.7 </span>You understand that paragraphs 6.3, 6.4 and 6.5 are subject to the terms and conditions of the third party service provider and the RTA (Registrar or Transfer Agent).</li>
        <li><span>6.8 </span>You understand that instructions given by you for transacting in mutual fund units will be forwarded to the AMC via a transaction processing platform in an electronic file format (“Feed File”) and the AMC will process the transaction on the basis of such Feed File only. You understand that an exit load or other charges may be levied by the AMC with respect to their mutual fund units, in accordance with the Scheme Related Documents.</li>
        <li><span>6.9 </span>You understand that there may be an interim time lag in processing, transferring, crediting the money from the third party service provider to the respective mutual fund account.</li>
        <li><span>6.10 </span>You understand that the purchase transactions of mutual fund units on the Platform shall be allotted an online folio number and you will be able to access this transaction account statement in electronic format directly from the respective AMC.</li>
        <li><span>6.11 </span>You understand that there may be a time gap between the completion of a transaction and the time taken by the Company to reflect the same on its Platform.
          <a href="#">Joint Bank Account</a>
        </li>
        <li><span>6.12 </span>You understand that when subscription for mutual fund units are remitted through a joint bank account, the default option for applying for mutual fund units would be in the joint names of all the bank account holders. If the payment is not made from a registered bank account or from an account not belonging to the first named unit holder, your transaction will be rejected.</li>
        <li><span>6.13 </span>You will be provided with an option to apply for units in a single name of any one or more names of the joint account holders of the bank account with a nomination facility subject to terms and conditions of the relevant Scheme Related Documents.</li>
        <li><span>6.14 </span>You understand that in a joint bank account holding, decision of the first holder as mentioned in paragraph 6.12 is binding on others. All instructions given by the first holder shall be binding on all others as if given by each of the holders personally. You hereby authorise the Company to process and transmit such instructions given from time to time in relation to your transactions to the respective AMC.</li>
        <li><span>6.15 </span>If an AMC rejects your request to transact, you will be informed of the same within 10 (Ten) business days from the Company’s receipt of this information and the amount debited will be credited into your bank account only after the receipt of the refund from the relevant AMC. </li>

        <li><span>6.16 </span>You understand that you may begin an online SIP facility, for investing a fixed amount of money at a fixed frequency of time in mutual funds based on your convenience and choice.</li>
        <li><span>6.17 </span>SIPs shall be set up through e-NACH mandates (“Mandate”). These are provided for by the AMC or third party service providers.</li>
        <li><span>6.18 </span>You understand that there could be a minimum time gap of 15 (Fifteen) days from the date of submission of a Mandate, to enable the registration and activation of an online SIP facility, subject to the successful validation of the Mandate by the banks.</li>
        <li><span>6.19 </span>You understand that the AMC may make take a minimum of 30 (Thirty) days to process a SIP deactivation request.</li>
        <li><span>6.20 </span>You authorise the third party service providers to debit your registered bank account as per the instructions in the Mandate issued by you in order to collect the SIP instalments amount and transfer the same to the respective AMC’s bank account. The RBI regulations related to a Mandate, are applicable as and when you avail such a facility.</li>
        <li><span>6.21 </span>You understand and acknowledge that in order for you to get the NAV on the SIP date (T date) chosen by you, debit of the SIP amount from your bank account shall occur prior to the SIP date (T), depending on your bank’s Mandate debit cycle.</li>
        <li><span>6.22 </span>You understand and acknowledge that, the third party service provider shall debit your bank based on a backward calculation from T date depending on the Mandate debit cycle. Thus, your account will be debited on T - number of days, such that on T date (SIP day), the monies reach the respective AMC’s bank account and the transaction data reaches the respective registrar of the AMC, to enable them to allot units as per the NAV on T date.</li>
      </ul>
      <h3><span>7.</span>NOMINATION</h3>
      <ul className="tos-links">
        <li><span>7.1 </span>Nomination shall be mandatory for every User. If you fail to nominate, your application to transact may be rejected at the discretion of the AMC.</li>
        <li><span>7.2 </span>You may nominate a maximum of 3 (Three) person(s) in whom the mutual funds held by you shall vest in the event of your death. You may be required to fill additional forms for the second and third nominee.</li>
        <li><span>7.3 </span>It is mandatory for you to indicate the percentage of allocation in favour of each of the nominees against their name and such allocation should be in whole numbers without any decimals adding to a total of 100 (Hundred) percent. You understand that if you do not indicate the percentage of allocation for each of the nominees, the AMCs, by invoking default option shall settle the claim equally amongst all the nominees.</li>
        <li><span>7.4 </span>You understand that the nomination made by you shall be applicable for investments in your Account and every new nomination for a folio will overwrite the existing nomination.</li>
        <li><span>7.5 </span>You understand that if a folio has joint holders, all joint holders should sign the request for nomination/cancellation of nomination.</li>
        <li><span>7.6 </span>A minor may be nominated provided, the name and address of the guardian of the minor nominee is provided by you.</li>
        <li><span>7.7 </span>Nomination shall not be allowed in a folio/Account held on behalf of a minor.</li>
        <li><span>7.8 </span>Nomination in respect of the mutual fund units, stands rescinded upon the transfer of such units. Transfer of units in favour of a nominee shall be a valid discharge by the AMC against the legal heir.</li>
        <li><span>7.9 </span>Cancellation of nomination can be made only by Users who hold mutual funds on their own behalf, single or jointly and who made the original nomination.</li>
        <li><span>7.10 </span>Upon cancellation of nomination, the nomination shall rescind and the AMC shall not be obligated to transfer such mutual funds in favour of the nominee.</li>
      </ul>
      <h3><span>8.</span>REDEMPTION</h3>
      <ul className="tos-links">
        <li><span>8.1 </span>All redemptions shall be subject to the provisions mentioned in the respective Scheme Related Documents and any amendments thereto issued from time to time.</li>
        <li><span>8.2 </span>If the number of mutual fund units requested by you are to be redeemed are lesser than the records maintained by the AMC - the available units will be redeemed.</li>
        <li><span>8.3 </span>If the redemption of ‘all’ mutual funds is specified in the request, all the mutual fund holdings in that scheme shall be redeemed.</li>
        <li><span>8.4 </span>You understand that the proceeds from redemption and declaration of dividend will be credited directly by the AMC into your bank account provided at the time of registration with the Company.</li>
      </ul>
      <h3><span>9.</span>THIRD PARTY CONTENT</h3>
      <ul className="tos-links">
        <li><span>9.1 </span>The Company may provide you with access to third party content, technology, or services (“Third Party Content”). All intellectual property rights in and to Third Party Content are the property of the respective third parties. Third Party Content is the responsibility of the third party that created or provided it and the Company excludes all warranties and liability pertaining to such Third Party Content. You confirm and understand that the Company does not endorse or support any Third Party Content displayed on or accessed via its Platform.</li>
        <li><span>9.2 </span>The Platform may provide you with a browsing experience through an embedded browser by aggregating the information available in public domain. The Company does not host or display any information or Content owned by any third party on the Platform except when such acts are permitted under applicable laws. You agree and acknowledge that the Platform does not display any Content on its own accord and is not responsible or liable for the content or accuracy of the Content that may be accessed by you through the Platform. You understand that the information accessed on or through the Platform and Services is for informational, educational and non-commercial purposes. </li>
      </ul>
      <h3><span>10.</span>USER REPRESENTATIONS AND WARRANTIES</h3>
      <ul className="tos-links">
        <li><span>10.1 </span>You as a User, hereby represent, warrant and undertake that:
          <ul className="sub-class">
            <li><span>a.</span>You have carefully reviewed the contents of the TOS and the Additional TOS and have understood the same and agreed to be bound by TOS and the Additional TOS.</li>
            <li><span>b.</span>You will not omit any information or provide misleading information to the Company.</li>
            <li><span>c.</span>Your actions on the Platform comply with all applicable laws and regulations in your jurisdiction, including, but not limited to legal capacity to enter into a contract.</li>
            <li><span>d.</span>You will not share your Account password or let anyone else access your Account or do anything else that might jeopardize the security of your Account or the Platform.</li>
            <li><span>e.</span>You agree that you will not use the Platform to perform any illegal or criminal activity of any sort, including but not limited to, money laundering, terrorist financing, or malicious hacking.</li>
            <li><span>f.</span>You will report all income associated with the investments via the Platform pursuant to the applicable laws and pay taxes as per the applicable laws in your jurisdiction.</li>
            <li><span>g.</span>You will not upload viruses or other malicious code on the Platform.</li>
            <li><span>h.</span>You will not interfere with any pages of the Company rendering the Platform (in whole or in part) or any other feature dysfunctional.</li>
            <li><span>i.</span>You will not use any robot, spider, crawler, scraper or other automated means or interface not provided by the Company to access the Services or to extract data.</li>
            <li><span>j.</span>You are not allowed to copy, reproduce, alter, modify, create derivative works of any Content displayed on the Platform.</li>
            <li><span>k.</span>You will not use the Services in any manner that could interfere with, disrupt, negatively affect or inhibit other Users from fully enjoying the Services, or that could damage, disable, overburden or impair the functioning of the Services in any manner.</li>
            <li><span>l.</span>You confirm that you have read all Scheme Related Documents carefully and that you have sought independent legal, financial and tax related advice. </li>
            <li><span>m.</span>You understand that your investment in mutual funds is subject to market risks and that the past results of a mutual fund are not a guarantee to its future performance.</li>
            <li><span>n.</span>You understand and agree to comply with the SEBI Know Your Client Regulations (“KYC”), requirements under the SEBI Act, 1992, Prevention of Money Laundering Act, 2002, all other applicable laws and regulations including but not limited to the Foreign Exchange Management Act, Reserve Bank of India (“RBI”) guidelines, to the extent applicable for availing the Services.</li>
            <li><span>o.</span>You agree to maintain sufficient balance in your bank account, at all times to cover your transaction/purchase price on the Platform. You agree to check your daily fund transfer limits with your bank before purchasing mutual fund units or making a SIP request. In the absence of sufficient funds, you understand that neither the Company nor the AMC will be responsible for the rejection of your instructions to transact/purchase mutual fund units.</li>
            <li><span>p.</span>You understand that the payment for all transactions undertaken by you through the Platform will be facilitated through a payment gateway that is set up by the relevant third party and AMC. You understand that the Platform shall not be liable for any failures in the link or for any fraud that could take place at the time of making the payment and that you shall inform the Company immediately if your bank account is debited, wherein corresponding mutual fund units have not been allotted.</li>
          </ul>
        </li>
      </ul>
      <h3><span>11.</span>ACKNOWLEDGEMENTS</h3>
      <div className="title">You acknowledge that:</div>
      <ul className="tos-links">
        <li><span>a.</span>All investment undertaken via the Platform will be investment in direct plans of relevant mutual funds. </li>
        <li><span>b.</span>The Services provided are presently available in respect of certain select mutual funds only and will be able to invest only in the selected mutual funds. </li>
        <li><span>c.</span>If your number is registered in the Do Not Disturb (DND) list of TRAI, you may not receive messages from the Company. You confirm that you shall not hold the Company liable in any manner for non-receipt of messages.</li>
      </ul>
      <h3><span>12.</span>DISCLAIMERS</h3>
      <ul className="tos-links">
        <li><span>12.2 </span>The Services are available to only residents of India.</li>
        <li><span>12.1 </span>The Platform and the Proprietary Software is provided to you on an “as is” and “as available” basis without any representation or warranty, whether express, implied, or statutory, and the Company expressly disclaims any implied warranties of title, merchantability, fitness for a particular purpose, and non-infringement, and does not make any representations or warranties that access to the Platform (including the Services) or the Proprietary Software, or any of the materials contained therein, will be continuous, uninterrupted, timely, error-free or secure.</li>
        <li><span>12.3 </span>The Services do not constitute an offer to sell or a solicitation of an offer to buy units of any mutual fund to any person in any jurisdiction where it is unlawful to make such an offer or solicitation. </li>
        <li><span>12.4 </span>You acknowledge and agree that the Company, its affiliates and any third parties engaged by the Company in relation to delivery of the Services, shall not be responsible for, and shall not be deemed to have any liability, directly or indirectly, for any loss or damage that occurs as a result of or allegedly as the result of:
          <ul>
            <li><span>a.</span>any incorrectness or inaccuracy of any Content; </li>
            <li><span>b.</span>the timeliness, deletion or removal, incorrect delivery or failure to store any User content, communications or personalization settings; </li>
            <li><span>c.</span>any error, omission or defect in, interruption, deletion, alteration, delay in operation or transmission, or unauthorised access to, any User Account or User communications;</li>
            <li><span>d.</span>any inaccuracies or delays in storing, reflecting, processing your data on the Platform;</li>
            <li><span>e.</span> e. any problems, failure or technical malfunction of any telephone network or lines, computer online systems, servers or providers, device equipment, software, failure of Platform on account of technical problems or server congestion on the internet, or at any website or combination thereof, including injury or damage to the User’s or to any third party’s computer or device, relating to or resulting from the User availing himself or herself of the Services.</li>
          </ul>
        </li>
        <li><span>12.5 </span>You understand that the Services are not offered or intended to serve as a vehicle for frequent tradin that seeks to take advantage of short term fluctuations in the market.
        </li>
        <li><span>12.6 </span>You understand that the AMC in its sole discretion may reject any transaction of mutual funds that it reasonably believes may represent a pattern of market timing activity involving the funds of the mutual fund.</li>
        <li><span>12.7 </span>You are aware that the Company does not:
          <ul>
            <li><span>a.</span>guarantee the accuracy, completeness, or usefulness of any reports, recommendations, information, data provided on the Platform; or</li>
            <li><span>b.</span> adopt, endorse or accept responsibility for the accuracy or reliability of any Content, opinion, advice, or statement made or uploaded by any third party on the Platform. </li>
          </ul>
        </li>
        <li><span>12.8 </span>you agree and acknowledge that under no circumstances will the Company or its affiliates be responsible or liable for any loss or damage resulting from your reliance on information, advice or Content made available through the Platform to you.
        </li>
        <li><span>12.9 </span>you agree and undertake that you are accessing the Platform and transacting at your own risk and that you are using your best and prudent judgment before acting pursuant to any advice or information contained on the Platform or communicated through access of the Services. </li>
        <li><span>12.10 </span>you accept full responsibility for any consequences that may arise from your use of the Content, Platform, Services and expressly agree and acknowledge that the Company shall have no liability with respect to the same.
        </li>
        <li><span>12.11 </span>you understand that the Company accepts no liability for any errors or omissions, rejection of instructions, whether on behalf of itself, other Users, or third parties, or for any damage caused to you, your belongings, or any third party, resulting from the use or misuse by you, of any Content, information, advice, or any actions or inactions made pursuant thereto. </li>
        <li><span>12.12 </span>Nothing contained herein shall be deemed to compel the Company to store, upload, publish, or display in any manner, content, reviews, surveys or feedback submitted by you, and you hereby expressly authorise the Company in its sole discretion to remove from the Platform any such content, review, survey, or feedback submitted or uploaded by you, without cause or being required to notify you of the same.
        </li>
        <li><span>12.13 </span>The details of various third party service providers and establishments, provided on the Platform are listed on the assumption that such third party service providers and establishments have complied with all the laws and regulations applicable to them for rendering their services.
        </li>
        <li><span>12.14 </span>You are aware and acknowledge that the Company may make available through the Platform, third party opinions, advice, statements, offers, or other Third Party Content. You are aware that all such Third Party Content is the responsibility of the respective authors thereof, and the Company does not make any warranties or guarantees with respect to the same. </li>
        <li><span>12.15 </span>You hereby expressly authorise the Company to disclose any and all information relating to you in the possession of the Company to any law enforcement or other government officials, if the Company believes it is necessary or appropriate in connection with the investigation or resolution of possible crimes, including but not limited to your identity, information provided by you, your banking information, and your correspondence. You further understand that the Company might be directed to disclose any such information as may be deemed necessary to satisfy any judicial order, law, regulation or valid governmental request.
        </li>
        <li><span>12.16 </span>The Company reserves the right at any time to add, modify or discontinue, temporarily or permanently, the Platform (or any part thereof) and Services, with or without notice or cause. You agree and acknowledge that the Company shall not be liable to you or to any third party for any such addition, modification, suspension or discontinuation of the Platform or the Services.

        </li>
        <li><span>12.17 </span>You are aware and acknowledge that to the maximum extent allowed by applicable laws, the Company grants no warranties of any kind, whether express, implied, statutory or otherwise with respect to the Platform (including all Content available therein), including without limitation any implied warranties of satisfactory quality, merchantability, fitness for a particular purpose or non-infringement.
        </li>
        <li><span>12.18 </span>You are aware and acknowledge that any material downloaded or otherwise obtained through the use of the Platform is accessed by you at your own discretion and risk, and that you will be solely responsible for, and do hereby waive any and all claims and causes of action with respect to, any damage to your device, computer system, internet access, or loss or corruption of your personal data, that results or may result from the use of the Platform. If you do not accept this disclaimer and the limitation on liability, you are not authorised to use or access the Platform.
        </li>
        <li><span>12.19 </span>You are aware and acknowledge that in the event of the termination of the arrangement between the Platform and an AMC, you will not be able to execute any transaction of such AMC’s mutual funds through the Platform. You understand that on the date of such termination, the Company will share the required details with the AMC, to enable you to interact directly with such AMCs.
        </li>
      </ul>
      <h3><span>13.</span> INVESTMENT ADVICE</h3>
      <ul className="tos-links">
        <li>
          <span>13.1 </span>The Company does not provide any financial or investment advice in connection with the Services offered on the Platform. The Company may provide information on the price, volatility, performance of mutual funds and events that have affected the price of mutual fund, however such information should not be considered as investment or other financial advice and should not be construed as such. You confirm that any decision to purchase or redeem mutual funds on the Platform is solely your decision and the Company will not be liable for any loss suffered whatsoever.
        </li>
        <li>
          <span>13.2 </span>You understand and acknowledge that the AMC will act only on the electronic transaction data forwarded by the Company and will be under no obligation to verify and/or confirm the authenticity of such transactions. You confirm that you will not hold the AMC liable and/or responsible for any loss/damage that you may incur as a result of any such improper use of your bank account.
        </li>
      </ul>
      <h3><span>14.</span> INTELLECTUAL PROPERTY </h3>
      <ul className="tos-links">
        <li>
          <span>14.1 </span>As long as you comply with these TOS, the Company grants to you, a limited, nonexclusive, non-transferable license, subject to the terms of these TOS, to access and use the Platform, Services including Third Party Services, its Content - solely for informational, transactional, or other purposes as may be permitted by the Company from time to time.
        </li>
        <li>
          <span>14.2 </span>You agree that these TOS do not grant you any rights in or licenses to the Platform, Services, Third Party Services and/or the Content, except only to the extent expressly provided in these TOS. The Company reserves all rights in the Platform, Services and the Contents (excluding Third Party Content).
        </li>
        <li>
          <span>14.3 </span>You are aware that the Company owns and retains all proprietary rights in the Platform, and in all proprietary Content, including trademarks, trade names, service marks and other intellectual property related thereto - excluding Third Party Content (“Intellectual Property”). The Platform contains the copyrighted material, trademarks, and other proprietary information of the Company and/or its licensors, and the User agrees not to copy, modify, transmit or create any derivative works from, make use of, or reproduce in any way any Intellectual Property or proprietary information accessible through the Platform.
        </li>
      </ul>
      <h3><span>15.</span>INDEMNIFICATION</h3>
      <ul className="tos-links">
        <li>
          <span>15.1 </span>To the fullest extent permitted by applicable law, you agree to indemnify, defend and hold harmless the Company and the Company’s past, present and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns from and against all claims, demands, actions, damages, losses, costs and expenses (including attorneys’ fees) that arise from or relate to: (i) Your use of the Platform and Services; (ii) Your responsibilities or obligations under these TOS; (iii) Your violation of these TOS; or (iv) Your violation of any rights of any other person or entity.
        </li>
        <li>
          <span>15.2 </span>The Company reserves the right to exercise sole control over the defence, at your expense, of any claim subject to indemnification. This indemnity is in addition to, and not in lieu of, any other indemnities set forth in a written agreement between you and Company.

        </li>
      </ul>
      <h3><span>16.</span> LIMITATION OF LIABILITY </h3>
      <ul className="tos-links">
        <li>
          <span>16.1 </span>To the fullest extent permitted by law, the Company and its related parties each disclaim all liability to you for any loss arising due to:
          <ul>
            <li>
              <span>a.
              </span>The use of, inability to use, or availability or unavailability of the Platform or Services. Including Third Party Services.

            </li>
            <li>
              <span>b.</span>The occurrence or existence of any defect, interruption, deletion of files, delays in the operation or transmission of information to, or from the Platform, communications failure, theft, destruction or unauthorised access to the Company’s records, programs, services, server, or other infrastructure relating to the Platform and the Services.
            </li>
            <li>
              <span>c.
              </span>The Platform or Services being infected with any malicious code or viruses.
            </li>
            <li>
              <span>d.
              </span>The Content posted on the Platform.
            </li>
            <li>
              <span>e.</span>The failure of the Platform to launch, or to remain operational for any period of time.
            </li>
            <li>
              <span>f.</span>
             Any war, riot, civil unrest, terrorist act, restraint imposed by any governmental or semi-governme authority, strike, lockout, work stoppage, commercial dispute, fire, explosion, storm, typhoon, fl lightning, earthquake, natural calamity, failure of power or communication system, or other act of Go event or circumstance beyond the reasonable control of the Company that renders performance commerci implausible.
            </li>
          </ul>
        </li>
        <li>
          <span>16.2 </span>Notwithstanding anything to the contrary, the maximum aggregate liability of the Company and its affiliates, for any loss shall not exceed INR 500/- (Five Hundred Rupees).
        </li>
      </ul>
      <h3><span>17.</span>SERVICE FEE</h3>
      <ul className="tos-links">
        <li>
          <span>17.1 </span>The use of the Platform is for free of cost, except for any charges that may be levied by third parties for the Third Part Services that may be availed. However, the User is aware that Company reserves the right to amend this policy and charge its Users for the Services, or any part thereof, at any time.

        </li>
        <li>
          <span>17.2 </span>The Company may also launch any additional or premium services not currently offered, which may be chargeable. The User expressly and unconditionally agrees to be bound by any such revisions of the fee policy and/or charges and additional terms and conditions if any additional or premium services are availed.
        </li>
      </ul>
      <h3><span>18.</span>TERm</h3>
      <ul className="tos-links">
        <li>
          <span>18.1 </span> The TOS will remain in full force and effect while you continue to access the Platform, avail the Services, or maintain an Account on the Platform.
        </li>
      </ul>
      <h3><span>19.</span>TERMINATION</h3>
      <ul className="tos-links">
        <li>
          <span>19.1 </span>
          Notwithstanding other legal remedies that may be available to it, the Company may at its sole discretion, limit your access or activity (by immediately removing your access credentials, either temporarily or indefinitely), or suspend or terminate your Account or refuse to provide you with access to the Platform and the Services, without providing you with notice or cause, for any reason including but not limited to the occurrence of the following events:
          <ul>
            <li>
              <span>a. </span>
              usage of the Service to perform any illegal or criminal activity such as money laundering, financing, terrorism, or hacking;
            </li>
            <li>
              <span>b.</span>
              breach of these TOS, the Privacy Policy or any Additional Terms;
            </li>
            <li>
              <span>c. </span>
              failure to pay or fraudulent payment for transactions; or
            </li>
            <li>
              <span>d. </span>
              upon the request of law enforcement or other government agencies.
            </li>
          </ul>
        </li>
        <li>
          <span>19.2 </span>
          You shall, however, continue to be governed by certain terms contained in these TOS that survive termination, and you expressly agree that you shall not have any right to terminate these TOS.
        </li>
        <li>
          <span>19.3 </span>
          Your only recourse hereunder shall be to stop accessing and using the Platform and availing the Services.

        </li>
      </ul>
      <h3><span>20.</span>COMMUNICATION</h3>
      <ul className="tos-links">
        <li>
          <span>20.1 </span>
          You are aware that while the primary mode of communication between the parties shall be through the Platform, notwithstanding anything contained herein, you may be contacted by the Company or any of its affiliates by any alternative means of communication, including without limitation, your mobile phone and email address, as set out in the Privacy Policy. You may be given the option to restrict or deny contact by the Company’s affiliates. If you consent to be contacted by the affiliates, these TOS will apply to all such communication
        </li>

      </ul>
      <h3><span>21.</span> WAIVER</h3>
      <ul className="tos-links">
        <li>
          <span>21.1 </span>
          Failure or delay by the Company in enforcing or partially enforcing any provision of these TOS shall not be construed as a waiver of any rights.
        </li>
        <li>
          <span>21.2 </span>
          A waiver shall not be valid and effective unless the same is in writing
        </li>
      </ul>
      <h3><span>22.</span>RELATIONSHIP OF THE PARTIES</h3>
      <ul className="tos-links">
        <li>
          <span>22.1 </span>
          You agree that (i) the Company does not act as a broker, intermediary, agent, or adviser, or in any fiduciary capacity, and (ii) no communication or information provided by the Company to you, shall be considered or constructed as advice.
        </li>

      </ul>
      <h3><span>23.</span>MODIFICATION</h3>
      <ul className="tos-links">
        <li>
          <span>23.1 </span>
          The Company reserves the right to suspend or discontinue the Services at any time. The Company further reserves the right to amend these TOS from time to time. Any changes or modifications made to these TOS by the Company shall be effective immediately.
        </li>

      </ul>
      <h3><span>24.</span>FEEDBACK</h3>
      <ul className="tos-links">
        <li>
          <span>24.1 </span>You understand and agree to fill up a feedback form that will be shared by the Company, via the Platform, which will help the Company understand your concerns and comments with respect to the functioning of the Platform.

        </li>
      </ul>
      <h3><span>25.</span>GOVERNING LAW AND DISPUTE RESOLUTION</h3>
      <ul className="tos-links">
        <li>
          <span>25.1 </span>
          These TOS shall be governed by and construed in accordance with the laws of India. Subject to paragraph 26.2, the courts of Ahmedabad shall have exclusive jurisdiction in connection with any disputes arising under these TOS.
        </li>
        <li>
          <span>25.2 </span>
          Any controversies, conflicts, disputes and/or differences arising out of these TOS or the Additional TOS shall be resolved by arbitration in Bangalore in accordance with the Arbitration and Conciliation Act, 1996 for the time being in force, the rules of which are deemed to be incorporated by reference in this Clause. The Tribunal will consist of one arbitrator. The language to be used during the arbitral proceedings shall be English. The award rendered by the arbitrator shall be final and binding and may be entered in any court having jurisdiction thereof.
        </li>
        <li>
          <span>25.3 </span>
          The parties shall maintain the confidential nature of the arbitration proceeding and the award, including the hearing, except as may be necessary to prepare for or conduct the arbitration hearing on the merits, or except as may be necessary in connection with a court application for a preliminary remedy, a judicial challenge to an award or its enforcement, or unless otherwise required by law or judicial decision.
        </li>
      </ul>
      <h3><span>26.</span> NOTICES</h3>
      <ul className="tos-links">
        <li>
          <span>26.1 </span>
          Any and all communication relating to any dispute or grievance experienced by the User may be communicated to the Company by contacting it at:
          <div className="link">
            Address: 801 Sapphire Complex, Opposite Union Bank of India, Near Cargo Motors, C.G Road, Ahmedabad-380006
            <br />
            Email:<a href="mailto:support@elevo.money">support@elevo.money</a>
          </div>
        </li>
        <li>
          <span>26.2 </span>
          You understand that you may communicate directly with the AMC to resolve any clarifications that you may have pertaining to your investments in a mutual funds.
        </li>
        <li>
          <span>26.3 </span>
          By using the Platform and Services, you agree that the Company may provide you with notices or other communication about your Account and the Services, electronically through emails (in each case to the address provided by you), or by posting on the Platform.
        </li>
      </ul>
    </section>
  </div>
 </>
}

export default TermsAndConditions