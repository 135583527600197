import { SHOW_HEDER_OPTIONS, NAV_SELECTION_CHANGE } from '../constant';

const initialState = {
    showCart: true,
    showPages: true,
    showProfile: true,
    showIcon: true,
    showProfileIcon: true,
    activeLink: 'home'
};

const headerReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_HEDER_OPTIONS:
      return {
        ...state,
        ...payload,
      };
    case NAV_SELECTION_CHANGE:
      return {
        ...state,
        activeLink: payload
      }
    default:
      return state;
  }
};

export default headerReducer;
