import React, { useState } from "react"
import  Carousel  from "react-multi-carousel";
import TaxStatusCards from "./TaxStatusCards"
import { Card, CardGroup, CardImg } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs"
const MobileTaxStatus = () => {
    const [cardData,setCardData] = useState(TaxStatusCards[0])
    const responsive =  {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          paritialVisibilityGutter: 60,
          slidesToSlide: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          paritialVisibilityGutter: 50,
          slidesToSlide: 1 
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 3,
          paritialVisibilityGutter: 30,
          slidesToSlide: 1 
        }
      };
    return (
        <>
        <section className="tax-status">
        <h2 className="header-title mx-3">What is your tax status?</h2>
        <p className=" mx-4 opacity-50">Start your investment journey to manage your business treasury more efficiently</p>
        <Carousel
             paritialVisibilityGutter
             swipeable={true}
             draggable={true}
             responsive={responsive}
             ssr={true} 
             infinite={true}
             keyBoardControl={true}
             customTransition="all .5"
             transitionDuration={500}
             removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
             itemClass="carousel-item-padding-40-px"
           >
             {TaxStatusCards.map(taxStatus =>{
                 return <>
                 <button  className={`btn br-light my-3 w-90 ${taxStatus.id===cardData.id ? "btn-primary":"btn-light"}`} onClick={() =>  setCardData(taxStatus)}>{taxStatus.name}</button>
                 </>
             })}
           </Carousel>
           <div className="colored-box mx-3 mt-4">
           <CardGroup>
                  <Card id="taxstatus-card">
                      <CardImg src={cardData.icon} id="taxstatus-img"></CardImg>
                      <Card.Title>{cardData.title}</Card.Title>
                      <Card.Subtitle >{cardData.subTitle}</Card.Subtitle>
                      <Card.Text>{cardData.header}</Card.Text>
                      <ol >
                          {cardData.description.map((value) => {return <><li className="align-items-justify">{value}</li></>})}
                      </ol>
                      <Card.Link href={process.env.REACT_APP_AUTH_URL} className="btn btn-primary w-80 m-3">Invest now <BsArrowRight size={28}/></Card.Link>
                  </Card>
              </CardGroup>
              </div>
        </section>
        </>
    )
}

export default MobileTaxStatus